import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.scss']
})
export class UserOverviewComponent implements OnInit {
  /**
   * Members
   */
  public userList: any[];
  
  /**
   * Constructor
   */
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  /**
   * OnInit
   */
  ngOnInit() {
    this.activatedRoute.data.subscribe(data => {
      this.loadUserList();
    });
  }

  async loadUserList() {
    this.userList = await this.userService.getUsers();
  }

  editUser(id: string) {
    this.router.navigate(['user', id]);
  }

  createUser() {
    this.router.navigate(['user', 0]);
  }

  async deleteUser(id: string) {
    await this.userService.deleteUser(id);
    this.loadUserList();
  }

  isAdmin() {
    return this.authService.isAdmin();
  }
}
