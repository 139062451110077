import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * members
   */
  authenticated: boolean;
  token: string;
  roles: string[];
  user: any;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.authenticated = false;
    this.roles         = [];
    this.user          = {};
  }

  isAuthenticated() {
    return this.authenticated;
  }

  setToken(token) {
    window.localStorage.setItem('auth-token', this.token);
  }

  getToken() {
    return window.localStorage.getItem('auth-token');
  }

  isAdmin() {
    return this.roles.filter(role => role === 'admin').length > 0;
  }

  getUser() {
    return this.user;
  }

  setUser(user) {
    this.user = user;
  }

  login(user: string, pass: string) {
    return this.http.post('https://beetl-server.blackroosteraudio.com/users/auth', { user, pass }).toPromise().then((res: any) => {
      // Update Members
      this.authenticated = true;
      this.token         = res.token;
      this.roles         = res.roles;
      this.user          = res.user;

      // Proceed
      this.setToken(this.token);
      this.router.navigate(['catalogues']);
    });
  }

  logout() {
    this.authenticated = false;
    this.token         = null;
    this.roles         = null;
    this.user          = null;
  }
}
