import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  /**
   * member
   */
  public user: string;
  public pass: string;
  public loginSuccess: boolean;

  /**
   * Constructor
   */
  constructor(private authService: AuthService) {
    this.authService.logout();
    this.loginSuccess = true;
  }

  /**
   * OnInit
   */
  ngOnInit() { }

  /**
   * Function will submit login
   */
  submit() {
    if (this.user) {
      this.authService.login(this.user, this.pass).catch(() => {
        this.loginSuccess = false;
      });
    } else {
      this.loginSuccess = false;
    }
  }
}
