import { Component, OnInit } from '@angular/core';
import { CatalogueService } from 'src/app/services/catalogue.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
  /**
   * Members
   */
  public matrix: any;
  public issuesOnly: any;

  /**
   * Constructor
   */
  constructor(
    private catalogueService: CatalogueService
  ) {
    this.issuesOnly = false;
  }

  /**
   * OnInit
   */
  ngOnInit() {
    this.loadResultList();
  }

  async loadResultList() {
    const list   = await this.catalogueService.getResults();
    const matrix = [];

    list.forEach(result => {
      if (result.originId) {
        matrix[result.originId]       = matrix[result.originId] || result;
        matrix[result.originId].steps = matrix[result.originId].steps || [];

        result.data.forEach((testStep, index) => {
          matrix[result.originId].steps[index] = matrix[result.originId].steps[index] || [];
          matrix[result.originId].steps[index].push({ ...testStep, tester: result.tester });
        });
      }
    });

    this.matrix = matrix;
  }

  toggleIssuesOnly() {
    this.issuesOnly = !this.issuesOnly;
  }
}
