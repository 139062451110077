import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  /**
   * Constructor
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Will retrieve user list
   */
  getUsers(): Promise<any> {
    return this.http.get('https://beetl-server.blackroosteraudio.com/users').toPromise();
  }

  /**
   * Will retreive user detail
   */
  getUserById(id: string): any {
    return this.http.get('https://beetl-server.blackroosteraudio.com/users/' + id).toPromise();
  }

  createUser(user: any, creator: any) {
    return this.http.post('https://beetl-server.blackroosteraudio.com/users', user).toPromise();
  }

  updateUser(id: string, user: any) {
    return this.http.put('https://beetl-server.blackroosteraudio.com/users/' + id, user).toPromise();
  }

  deleteUser(id: string): any {
    return this.http.delete('https://beetl-server.blackroosteraudio.com/users/' + id).toPromise();
  }

  updateProfile(id: string, user: any) {
    return this.http.put('https://beetl-server.blackroosteraudio.com/users/profile/' + id, user).toPromise();
  }
}
