import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  /**
   * Members
   */
  public message:string;

  /**
   * Constructor
   */
  constructor(private notificationService: NotificationService) {}

  /**
   * OnInit
   */
  ngOnInit() {
    this.notificationService.currentMessage.subscribe(message => this.message = message)
  }
}
