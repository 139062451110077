import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CatalogueService {
  /**
   * Constructor
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Will retrieve catalogue list
   */
  getCatalogues(): Promise<any> {
    return this.http.get('https://beetl-server.blackroosteraudio.com/catalogues').toPromise();
  }

  /**
   * Will retreive catalogue detail
   */
  getCatalogueById(id: string): any {
    return this.http.get('https://beetl-server.blackroosteraudio.com/catalogues/' + id).toPromise();
  }

  createCatalogue(catalogue: any) {
    return this.http.post('https://beetl-server.blackroosteraudio.com/catalogues', catalogue).toPromise();
  }

  updateCatalogue(id: string, catalogue: any) {
    return this.http.put('https://beetl-server.blackroosteraudio.com/catalogues/' + id, catalogue).toPromise();
  }

  deleteCatalogue(id: string): any {
    return this.http.delete('https://beetl-server.blackroosteraudio.com/catalogues/' + id).toPromise();
  }

  /**
   * Will retreive results and aggregate
   */
  getResults(): any {
    return this.http.get('https://beetl-server.blackroosteraudio.com/results').toPromise();
  }

  createResult(catalogue: any) {
    return this.http.post('https://beetl-server.blackroosteraudio.com/results', catalogue).toPromise();
  }
}
