import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  /**
   * Members
   */
  private messageSource = new BehaviorSubject(null);
  public currentMessage = this.messageSource.asObservable();

  /**
   * Constructor
   */
  constructor() { }

  /**
   * Change message function
   * @param message
   */
  changeMessage(message: string) {
    this.messageSource.next(message)
  }
}
