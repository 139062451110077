import { Component, OnInit, OnChanges, Input, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, NavigationEnd, RouterEvent, NavigationCancel } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  /**
   * Members
   */
  private routerSub: Subscription;
  public user: any;

  /**
   * Constructor
   */
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
     this.routerSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd || val instanceof NavigationCancel) {
        const user = this.authService.getUser() || {};
        this.user = user.username ? user : null;
      }
    });
  }

  /**
   * OnDestroy
   */
  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }
}
