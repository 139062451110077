import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { NotificationComponent } from './components/notification/notification.component';
import { CatalogueOverviewComponent } from './components/catalogues/catalogue-overview/catalogue-overview.component';
import { CatalogueDetailComponent } from './components/catalogues/catalogue-detail/catalogue-detail.component';
import { UserOverviewComponent } from './components/users/user-overview/user-overview.component';
import { UserDetailComponent } from './components/users/user-detail/user-detail.component';
import { LoginComponent } from './components/login/login.component';
import { AuthInterceptor } from './utils/auth.interceptor';
import { AuthService } from './services/auth.service';
import { NotificationService } from './services/notification.service';
import { AuthGuard } from './guards/auth.guard';
import { ResultComponent } from './components/catalogues/result/result.component';
import { Nl2BrPipeModule } from 'nl2br-pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NotificationComponent,
    CatalogueOverviewComponent,
    CatalogueDetailComponent,
    UserOverviewComponent,
    UserDetailComponent,
    LoginComponent,
    ResultComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    Nl2BrPipeModule
  ],
  providers: [
    AuthService,
    NotificationService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
