import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CatalogueOverviewComponent } from './components/catalogues/catalogue-overview/catalogue-overview.component';
import { CatalogueDetailComponent } from './components/catalogues/catalogue-detail/catalogue-detail.component';
import { UserOverviewComponent } from './components/users/user-overview/user-overview.component';
import { UserDetailComponent } from './components/users/user-detail/user-detail.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { ResultComponent } from './components/catalogues/result/result.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'catalogues',
    component: CatalogueOverviewComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'catalogue/:id',
    component: CatalogueDetailComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'results',
    component: ResultComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'users',
    component: UserOverviewComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'user/:id',
    component: UserDetailComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'profile',
    component: UserDetailComponent,
    data: { isProfile: true },
    canActivate: [ AuthGuard ]
  },
  {
    path: '',
    redirectTo: '/catalogues',
    pathMatch: 'full',
    canActivate: [ AuthGuard ]
  },
  {
    path: '**',
    redirectTo: '/catalogues',
    pathMatch: 'full',
    canActivate: [ AuthGuard ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
