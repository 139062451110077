import { Component, OnInit } from '@angular/core';
import { CatalogueService } from 'src/app/services/catalogue.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-catalogue-overview',
  templateUrl: './catalogue-overview.component.html',
  styleUrls: ['./catalogue-overview.component.scss']
})
export class CatalogueOverviewComponent implements OnInit {
  /**
   * Members
   */
  public catalogueList: any[];

  /**
   * Constructor
   */
  constructor(
    private catalogueService: CatalogueService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  /**
   * OnInit
   */
  ngOnInit() {
    this.activatedRoute.data.subscribe(data => {
      this.loadCatalogueList();
    });
  }

  async loadCatalogueList() {
    this.catalogueList = await this.catalogueService.getCatalogues();
  }

  editCatalogue(id: string) {
    this.router.navigate(['catalogue', id]);
  }

  createCatalogue() {
    this.router.navigate(['catalogue', 0]);
  }

  async deleteCatalogue(id: string) {
    await this.catalogueService.deleteCatalogue(id);
    this.loadCatalogueList();
  }

  public isAdmin() {
    return this.authService.isAdmin();
  }
}
