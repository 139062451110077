import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {
  /**
   * Members
   */
  private sub: any;
  public user: any;
  public canEdit: boolean;
  public isAdmin: boolean;
  public isProfile: boolean;

  /**
   * Constructor
   */
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private authService: AuthService
  ) {
    this.user = { data: {}};
    this.isAdmin = false;
  }

  /**
   * OnInit
   */
  ngOnInit() {
    this.canEdit = this.authService.isAdmin();

    // Load user from data
    this.route.data.subscribe(data => {
      if (data.isProfile) {
        this.isProfile  = true;
        this.canEdit    = true;
        const profileId = this.authService.getUser().id;
        this.loadUser(profileId);
      }
    });

    // Load user from parameter
    this.sub = this.route.params.subscribe(params => {
      if (params.id) {
        this.loadUser(params.id);
      }
    });
  }

  /**
   * OnDestroy
   */
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * Function will load user detail by id
   * @param id
   */
  loadUser(id: string) {
    this.userService.getUserById(id).then((result) => {
      const userIsAdmin = result && result.roles ? (result.roles.filter(filter => filter === 'admin').length > 0) : false;
      this.user = {
        ...this.user,
        ...result,
        isAdmin: userIsAdmin
      };
    });
  }

  /**
   * Function will save a user object
   */
  saveUser() {
    if (this.user.username) {
      if (this.user.isAdmin) {
        this.user.roles = ['user', 'admin'];
      } else {
        this.user.roles = ['user'];
      }

      if (this.user.id) {
        this.userService.updateUser(this.user.id, this.user).then((result) => {
          this.user = { ...this.user, ...result };
        });
      } else {
        this.userService.createUser(this.user, this.authService.getUser()).then((result) => {
          this.user = { ...this.user, ...result };
        });
      }
      return true;
    }
    return false;
  }

  saveUserAndClose() {
    if (this.saveUser()) {
      this.router.navigate(['/users']);
    }
  }

  saveProfile() {
    if (this.user.username) {
      if (this.user.isAdmin) {
        this.user.roles = ['user', 'admin'];
      } else {
        this.user.roles = ['user'];
      }

      this.userService.updateProfile(this.user.id, this.user).then((result: any) => {
        if (result && result.id === this.authService.getUser()) {
          this.authService.setUser(result);
        }
        this.router.navigate(['/catalogues']);
      });
      return true;
    }
    return false;
  }
}
