import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  /**
   * Constructor
   */
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  /**
   * Redirect to login on unauthed user
   */
  canActivate() {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['login']);
  }
}
